import './style.scss';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CognyAPI from 'components/_classes/CognyAPI';
import { useParams } from 'react-router-dom';
import Form from 'components/atoms/Form';
import Button from 'components/atoms/Button';
import SubmitStatus from 'components/molecules/SubmitStatus';
import LoadingDots from 'components/atoms/LoadingDots';
import Field from 'components/molecules/Field';
import { useCookies } from 'react-cookie';
import PageHeader from 'components/organisms/PageHeader';
import { useAppData } from 'hooks/useAppData';

function FieldRow(props) {
  return (
    <div className="FieldRow">
      {props.children}
    </div>
  );
}

const SubmitButton = (props) => {
  const { isLoaded, error } = props;
  return (
    <>
      <Button type="submit" className="DatasourceGuide__submit" icon="arrowRight" iconSide="right">Add data source</Button>
      <SubmitStatus isLoaded={isLoaded} error={error} parentClass="DatasourceGuide" successMessage="" />
    </>
  )
}

function DatasourceGuide(props) {
  const [task, setTask] = useState({});
  const [name, setName] = useState("default");
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(true);
  const [, setValidation] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const [cookies] = useCookies(["token", "project"]);
  const nameValidation = /^[a-z_0-9]*$/;
  const warehouseId = params.warehouse_id;
  const { getPath } = useAppData();

  const validateTask = (name, type) => {
    return typeof nameValidation.test(name) && typeof type !== "undefined" && type.length;
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    setValidation(true);

    if (!validateTask(task.name, task.type)) {
      return;
    }

    setIsLoaded(false);

    const newDatasource = {
      name: task.name,
      type: task.type,
      provider: task.provider,
    };

    if (cookies.token) {
      const api = new CognyAPI(cookies.token);
      if (window.dataLayer) {
        window.dataLayer.push({ event: "add_datasource", datasource_type: task.type, token: cookies.token });
      }
      api.addDatasource(warehouseId, newDatasource)
        .then(
          (result) => {
            setIsLoaded(true);
            setTask(prevState => ({
              ...prevState,
              ...result
            }));

            navigate(getPath("datasources", result.gid));
            window.location.reload();
          },
          (error) => {
            setIsLoaded(true);
            console.log(error);
            setError(error);
          }
        )
    }
  }

  const connectors = [
    {
      "id": "aws_cloudtrail",
      "name": "AWS CloudTrail",
      "type": "File",
      "description": "Load cloudtrail files stored in S3",
      "icon_url": "https://fivetran.com/integrations/aws_cloudtrail/resources/aws_cloudtrail.svg",
      "provider": "fivetran"
    },
    {
      "id": "aws_inventory",
      "name": "AWS Inventory",
      "type": "API",
      "description": "AWS Inventory provides you with an overview of your AWS assets, including instances, security groups etc.",
      "icon_url": "https://fivetran.com/integrations/aws_inventory/resources/aws_inventory.svg",
      "provider": "fivetran"
    },
    {
      "id": "aws_lambda",
      "name": "AWS Lambda",
      "type": "Function",
      "description": "Run your code using AWS Lambda connector",
      "icon_url": "https://fivetran.com/integrations/functions/aws_lambda/resources/logo.png",
      "provider": "fivetran"
    },
    {
      "id": "adroll",
      "name": "AdRoll",
      "type": "Marketing",
      "description": "AdRoll is prospecting and retargeting platform",
      "icon_url": "https://fivetran.com/integrations/adroll/resources/adroll.svg",
      "provider": "fivetran"
    },
    {
      "id": "adobe_analytics",
      "name": "Adobe Analytics",
      "type": "Marketing",
      "description": "Adobe Analytics provides marketing analytics and reporting tools.",
      "icon_url": "https://fivetran.com/integrations/adobe_analytics/resources/adobe-analytics-symbol.svg",
      "provider": "fivetran"
    },
    {
      "id": "adobe_analytics_data_feed",
      "name": "Adobe Analytics Data Feed",
      "type": "Marketing",
      "description": "Adobe Analytics Data Feeds provide raw events data",
      "icon_url": "https://fivetran.com/integrations/adobe_analytics_data_feed/resources/adobe-analytics-data-feed-symbol.svg",
      "provider": "fivetran"
    },
    {
      "id": "airtable",
      "name": "Airtable",
      "type": "Productivity",
      "description": "Airtable works like a spreadsheet but gives you the power of a database to organize anything",
      "icon_url": "https://fivetran.com/integrations/airtable/resources/logo.svg",
      "provider": "fivetran"
    },
    {
      "id": "amplitude",
      "name": "Amplitude",
      "type": "Events",
      "description": "Amplitude is the comprehensive product analytics software for web and mobile",
      "icon_url": "https://fivetran.com/integrations/amplitude/resources/logo.svg",
      "provider": "fivetran"
    },
    {
      "id": "anaplan",
      "name": "Anaplan",
      "type": "Finance",
      "description": "Cloud platform for financial and operational planning and modeling of business processes.",
      "icon_url": "https://fivetran.com/integrations/anaplan/resources/anaplan.svg",
      "provider": "fivetran"
    },
    {
      "id": "apache_kafka",
      "name": "Apache Kafka",
      "type": "Events",
      "description": "Apache Kafka is an open source distributed streaming platform",
      "icon_url": "https://fivetran.com/integrations/kafka/resources/apache_kafka.svg",
      "provider": "fivetran"
    },
    {
      "id": "itunes_connect",
      "name": "Apple App Store",
      "type": "Marketing",
      "description": "Payments and Financial Reports, Sales and Trends, App Analytics and Reviews for Apple App Store Apps.",
      "icon_url": "https://fivetran.com/integrations/itunes_connect/resources/itunes_connect_logo.png",
      "provider": "fivetran"
    },
    {
      "id": "apple_search_ads",
      "name": "Apple Search Ads",
      "type": "Marketing",
      "description": "Apple Search Ads helps people discover your app on the App Store for iPhone and iPad",
      "icon_url": "https://fivetran.com/integrations/apple_search_ads/resources/apple_search_ads.svg",
      "provider": "fivetran"
    },
    {
      "id": "appsflyer",
      "name": "AppsFlyer",
      "type": "Marketing",
      "description": "AppsFlyer is mobile attribution & marketing analytics platform, helping app marketers around the world make better decisions.",
      "icon_url": "https://fivetran.com/integrations/appsflyer/resources/appsflyer.svg",
      "provider": "fivetran"
    },
    {
      "id": "asana",
      "name": "Asana",
      "type": "Productivity",
      "description": "Teamwork without email",
      "icon_url": "https://fivetran.com/integrations/asana/resources/asana.svg",
      "provider": "fivetran"
    },
    {
      "id": "aurora",
      "name": "Aurora MySQL",
      "type": "Database",
      "description": "Amazon Aurora is a high-performance MySQL-compatible database system used to keep in-house custom data",
      "icon_url": "https://fivetran.com/integrations/mysql/resources/logos/aurora.png",
      "provider": "fivetran"
    },
    {
      "id": "aurora_postgres",
      "name": "Aurora Postgres",
      "type": "Database",
      "description": "Aurora Postgres is a hosted version of the popular Postgres database on the Amazon Aurora platform",
      "icon_url": "https://fivetran.com/integrations/postgres/resources/aurora.png",
      "provider": "fivetran"
    },
    {
      "id": "azure_blob_storage",
      "name": "Azure Blob Storage",
      "type": "File",
      "description": "Load files stored in Azure Blob Storage",
      "icon_url": "https://fivetran.com/integrations/azure_blob_storage/resources/azure_blob_storage.svg",
      "provider": "fivetran"
    },
    {
      "id": "azure_function",
      "name": "Azure Function",
      "type": "Function",
      "description": "Run your code using Azure Function connector",
      "icon_url": "https://fivetran.com/integrations/functions/azure_function/resources/logo.svg",
      "provider": "fivetran"
    },
    {
      "id": "maria_azure",
      "name": "Azure MariaDB",
      "type": "Database",
      "description": "Azure MariaDB is a hosted version of the popular Maria database on the Azure platform",
      "icon_url": "https://fivetran.com/integrations/mysql/resources/logos/maria_azure.png",
      "provider": "fivetran"
    },
    {
      "id": "mysql_azure",
      "name": "Azure MySQL",
      "type": "Database",
      "description": "Microsoft Azure MySQL is a high-performance MySQL-compatible database system used to keep in-house custom data",
      "icon_url": "https://fivetran.com/integrations/mysql/resources/logos/azure.png",
      "provider": "fivetran"
    },
    {
      "id": "azure_postgres",
      "name": "Azure PostgreSQL Database",
      "type": "Database",
      "description": "Azure SQL Database is a managed relational cloud database service",
      "icon_url": "https://fivetran.com/integrations/postgres/resources/azure.png",
      "provider": "fivetran"
    },
    {
      "id": "azure_sql_db",
      "name": "Azure SQL Database",
      "type": "Database",
      "description": "Azure SQL Database is a managed relational cloud database service",
      "icon_url": "https://fivetran.com/integrations/sql_server/resources/azure.png",
      "provider": "fivetran"
    },
    {
      "id": "azure_sql_managed_db",
      "name": "Azure SQL Managed Instance",
      "type": "Database",
      "description": "Azure SQL Managed Instance is a managed relational cloud database service",
      "icon_url": "https://fivetran.com/integrations/sql_server/resources/azure.png",
      "provider": "fivetran"
    },
    {
      "id": "box",
      "name": "Box",
      "type": "File",
      "description": "Load files stored in Box",
      "icon_url": "https://fivetran.com/integrations/box/resources/box-icon.svg",
      "provider": "fivetran"
    },
    {
      "id": "braintree",
      "name": "Braintree",
      "type": "Finance",
      "description": "Braintree is a payment solution for selling to customers on web, mobile, and native app using credit and debit cards, PayPal, etc. payment methods.",
      "icon_url": "https://fivetran.com/integrations/braintree/resources/braintree.svg",
      "provider": "fivetran"
    },
    {
      "id": "braintree_sandbox",
      "name": "Braintree Sandbox",
      "type": "Finance",
      "description": "Braintree is a payment solution for selling to customers on web, mobile, and native app using credit and debit cards, PayPal, etc. payment methods.",
      "icon_url": "https://fivetran.com/integrations/braintree/resources/braintree.svg",
      "provider": "fivetran"
    },
    {
      "id": "branch",
      "name": "Branch",
      "type": "Marketing",
      "description": "Branch tracks event on your app",
      "icon_url": "https://fivetran.com/integrations/branch/resources/branch.svg",
      "provider": "fivetran"
    },
    {
      "id": "braze",
      "name": "Braze",
      "type": "Marketing",
      "description": "Braze is a mobile marketing application that provides mobile marketing automation that businesses use to manage their mobile applications.",
      "icon_url": "https://fivetran.com/integrations/braze/resources/braze.png",
      "provider": "fivetran"
    },
    {
      "id": "cloudfront",
      "name": "Cloudfront",
      "type": "File",
      "description": "",
      "icon_url": "https://fivetran.com/integrations/cloudfront/resources/cloudfront.svg",
      "provider": "fivetran"
    },
    {
      "id": "confluent_cloud",
      "name": "Confluent Cloud",
      "type": "Events",
      "description": "Confluent Cloud is a managed distributed streaming platform",
      "icon_url": "https://fivetran.com/integrations/kafka/resources/confluent_cloud.svg",
      "provider": "fivetran"
    },
    {
      "id": "coupa",
      "name": "Coupa",
      "type": "Finance",
      "description": "Coupa Software is a global technology platform for Business Spend Management",
      "icon_url": "https://fivetran.com/integrations/coupa/resources/coupa_logo.svg",
      "provider": "fivetran"
    },
    {
      "id": "criteo",
      "name": "Criteo",
      "type": "Marketing",
      "description": "Criteo helps internet retailers serve personalized advertisements through retargeting",
      "icon_url": "https://fivetran.com/integrations/criteo/resources/criteo.png",
      "provider": "fivetran"
    },
    {
      "id": "delighted",
      "name": "Delighted",
      "type": "Support",
      "description": "Delighted collects surveys from your customers",
      "icon_url": "https://fivetran.com/integrations/delighted/resources/delighted.svg",
      "provider": "fivetran"
    },
    {
      "id": "dropbox",
      "name": "Dropbox",
      "type": "File",
      "description": "Load files from a Dropbox account",
      "icon_url": "https://fivetran.com/integrations/dropbox/resources/dropbox.svg",
      "provider": "fivetran"
    },
    {
      "id": "dynamics_365",
      "name": "Dynamics 365",
      "type": "Sales",
      "description": "Dynamics 365 provides seamless solution for CRM and ERP",
      "icon_url": "https://fivetran.com/integrations/dynamics365/resources/logo.svg",
      "provider": "fivetran"
    },
    {
      "id": "dynamics_365_fo",
      "name": "Dynamics 365 Finance and Operations",
      "type": "Database",
      "description": "An Enterprise Resource Planning system for medium to large businesses",
      "icon_url": "https://fivetran.com/integrations/dynamics365/resources/logo.svg",
      "provider": "fivetran"
    },
    {
      "id": "dynamodb",
      "name": "DynamoDB",
      "type": "Database",
      "description": "DynamoDB integration",
      "icon_url": "https://fivetran.com/integrations/dynamodb/resources/dynamodb.svg",
      "provider": "fivetran"
    },
    {
      "id": "eloqua",
      "name": "Eloqua",
      "type": "Marketing",
      "description": "Marketing Automation platform for managing sales and marketing leads across an enterprise",
      "icon_url": "https://fivetran.com/integrations/eloqua/resources/eloqua.svg",
      "provider": "fivetran"
    },
    {
      "id": "email",
      "name": "Email",
      "type": "File",
      "description": "Sync email attachments to your data warehouse",
      "icon_url": "https://fivetran.com/integrations/email/resources/email.svg",
      "provider": "fivetran"
    },
    {
      "id": "ftp",
      "name": "FTP",
      "type": "File",
      "description": "Load files stored in an FTP server",
      "icon_url": "https://fivetran.com/integrations/ftp/resources/ftp.svg",
      "provider": "fivetran"
    },
    {
      "id": "facebook_ad_account",
      "name": "Facebook Ad Account",
      "type": "Marketing",
      "description": "Facebook Ad Account provides attribute data on Facebook Ad Accounts",
      "icon_url": "https://fivetran.com/integrations/facebook/resources/facebook.svg",
      "provider": "fivetran"
    },
    {
      "id": "facebook",
      "name": "Facebook Ad Insights",
      "type": "Marketing",
      "description": "Facebook Ad Insights is an API that provides data and metrics on Facebook Ads.",
      "icon_url": "https://fivetran.com/integrations/facebook/resources/facebook.svg",
      "provider": "fivetran"
    },
    {
      "id": "facebook_ads",
      "name": "Facebook Ads",
      "type": "Marketing",
      "description": "Facebook Ads provides attribute data on Facebook Ads Accounts and Reports",
      "icon_url": "https://fivetran.com/integrations/facebook/resources/facebook.svg",
      "provider": "fivetran"
    },
    {
      "id": "facebook_pages",
      "name": "Facebook Pages",
      "type": "Marketing",
      "description": "Sync data from your Facebook Pages.",
      "icon_url": "https://fivetran.com/integrations/facebook/resources/facebook.svg",
      "provider": "fivetran"
    },
    {
      "id": "financial_force",
      "name": "FinancialForce",
      "type": "API",
      "description": "FinancialForce is a cloud ERP solution for salesforce",
      "icon_url": "https://fivetran.com/integrations/salesforce/resources/financial_force.svg",
      "provider": "fivetran"
    },
    {
      "id": "fivetran_log",
      "name": "Fivetran Log",
      "type": "Free",
      "description": "Fivetran log connector provides logs and metadata information to the users about their existing connectors.",
      "icon_url": "https://fivetran.com/integrations/fivetran_log/resources/fivetran.svg",
      "provider": "fivetran"
    },
    {
      "id": "freshdesk",
      "name": "Freshdesk",
      "type": "Support",
      "description": "Freshdesk is a SaaS customer support software and help desk ticketing system.",
      "icon_url": "https://fivetran.com/integrations/freshdesk/resources/freshdesk.svg",
      "provider": "fivetran"
    },
    {
      "id": "front",
      "name": "Front",
      "type": "Support",
      "description": "Front provides cloud based service for multi-channel inbox",
      "icon_url": "https://fivetran.com/integrations/front/resources/front.svg",
      "provider": "fivetran"
    },
    {
      "id": "github",
      "name": "GitHub",
      "type": "Engineering",
      "description": "GitHub hosts Git source code repositories",
      "icon_url": "https://fivetran.com/integrations/github/resources/github.svg",
      "provider": "fivetran"
    },
    {
      "id": "double_click_publishers",
      "name": "Google Ad Manager",
      "type": "Marketing",
      "description": "Google Ad Manager is a hosted ad serving platform",
      "icon_url": "https://fivetran.com/integrations/google_ad_manager/resources/google_ad_manager.png",
      "provider": "fivetran"
    },
    {
      "id": "google_analytics_4",
      "name": "Google Analytics 4",
      "type": "Marketing",
      "description": "Google Analytics 4 provides metrics on how people use your website",
      "icon_url": "https://fivetran.com/integrations/google_analytics/resources/google_analytics.svg",
      "provider": "Google"
    },
    {
      "id": "google_ads",
      "name": "Google Ads",
      "type": "Marketing",
      "description": "Google Ads Reporting syncs Google search ad aggregate results",
      "icon_url": "https://fivetran.com/integrations/adwords/resources/google_ads.png",
      "provider": "fivetran"
    },
    {
      "id": "google_analytics",
      "name": "Google Analytics (UA)",
      "type": "Marketing",
      "description": "Google Universal Analytics provides metrics on how people use your website",
      "icon_url": "https://fivetran.com/integrations/google_analytics/resources/google_analytics.svg",
      "provider": "fivetran"
    },
    {
      "id": "google_analytics_mcf",
      "name": "Google Analytics (MCF)",
      "type": "Marketing",
      "description": "Google Analytics MCF shows user interactions with various traffic sources over multiple sessions prior to converting.",
      "icon_url": "https://fivetran.com/integrations/google_analytics/resources/google_analytics_mcf.svg",
      "provider": "fivetran"
    },
    {
      "id": "google_analytics_360",
      "name": "Google Analytics 360",
      "type": "Marketing",
      "description": "Google Analytics 360 provides the tools and support that enterprise teams need to get actionable insights from their data.",
      "icon_url": "https://fivetran.com/integrations/google_analytics_360/resources/google-analytics-360.svg",
      "provider": "fivetran"
    },
    {
      "id": "double_click_campaign_manager",
      "name": "Google Campaign Manager 360",
      "type": "Marketing",
      "description": "Google Campaign Manager 360 simplifies advertising campaign management, from media planning to reporting.",
      "icon_url": "https://fivetran.com/integrations/double_click_campaign_manager/resources/campaign_manager.svg",
      "provider": "fivetran"
    },
    {
      "id": "google_cloud_function",
      "name": "Google Cloud Function",
      "type": "Function",
      "description": "Run your code using Google Cloud Function connector",
      "icon_url": "https://fivetran.com/integrations/functions/google_cloud_function/resources/logo.svg",
      "provider": "fivetran"
    },
    {
      "id": "google_cloud_mysql",
      "name": "Google Cloud MySQL",
      "type": "Database",
      "description": "Cloud SQL is a fully-managed database on Google Cloud Platform",
      "icon_url": "https://fivetran.com/integrations/mysql/resources/logos/cloudsql.png",
      "provider": "fivetran"
    },
    {
      "id": "google_cloud_postgresql",
      "name": "Google Cloud PostgreSQL",
      "type": "Database",
      "description": "Google Cloud SQL Postgres is a hosted version of the popular Postgres database on the Google Cloud platform",
      "icon_url": "https://fivetran.com/integrations/postgres/resources/cloudsql.png",
      "provider": "fivetran"
    },
    {
      "id": "google_cloud_sqlserver",
      "name": "Google Cloud SQL - SQL Server",
      "type": "Database",
      "description": "Fully managed SQL Server databases in the cloud",
      "icon_url": "https://fivetran.com/integrations/sql_server/resources/cloudsql.png",
      "provider": "fivetran"
    },
    {
      "id": "gcs",
      "name": "Google Cloud Storage",
      "type": "File",
      "description": "Load files stored in GCS",
      "icon_url": "https://fivetran.com/integrations/gcs/resources/gcs.svg",
      "provider": "fivetran"
    },
    {
      "id": "google_display_and_video_360",
      "name": "Google Display & Video 360",
      "type": "Marketing",
      "description": "Display & Video 360 is a tool that helps creative, data, and media teams work together to execute end-to-end campaigns.",
      "icon_url": "https://fivetran.com/integrations/google_display_and_video_360/resources/google-display-and-video-360.svg",
      "provider": "fivetran"
    },
    {
      "id": "google_drive",
      "name": "Google Drive",
      "type": "File",
      "description": "Lets you get all the files in a magic folder converted to tables.",
      "icon_url": "https://fivetran.com/integrations/gdrive/resources/google-drive-icon.svg",
      "provider": "fivetran"
    },
    {
      "id": "google_play",
      "name": "Google Play",
      "type": "Marketing",
      "description": "Load Android app analytics from the Google Play store",
      "icon_url": "https://fivetran.com/integrations/google_play/resources/google_play.svg",
      "provider": "fivetran"
    },
    {
      "id": "google_search_console",
      "name": "Google Search Console",
      "type": "Marketing",
      "description": "Google Search Console allows you to check indexing status and optimize visibility of your websites",
      "icon_url": "https://fivetran.com/integrations/google_search_console/resources/google-search-console-logo.svg",
      "provider": "fivetran"
    },
    {
      "id": "google_sheets",
      "name": "Google Sheets",
      "type": "File",
      "description": "Google Sheets are web-based shared spreadsheets",
      "icon_url": "https://fivetran.com/integrations/gsheets/resources/gsheets.svg",
      "provider": "fivetran"
    },
    {
      "id": "greenhouse",
      "name": "Greenhouse",
      "type": "HumanResources",
      "description": "Greenhouse is an applicant tracking system and recruiting software.",
      "icon_url": "https://fivetran.com/integrations/greenhouse/resources/greenhouse.svg",
      "provider": "fivetran"
    },
    {
      "id": "heap",
      "name": "Heap",
      "type": "Events",
      "description": "Heap is an automation tool for user analytics.",
      "icon_url": "https://fivetran.com/integrations/heap/resources/heap.svg",
      "provider": "fivetran"
    },
    {
      "id": "height",
      "name": "Height",
      "type": "Productivity",
      "description": "Height is a collaborative work service that helps to maintain a project workflow.",
      "icon_url": "https://fivetran.com/integrations/height/resources/height.svg",
      "provider": "fivetran"
    },
    {
      "id": "helpscout",
      "name": "Help Scout",
      "type": "Support",
      "description": "Help Scout is a help desk software that provides an email-based customer support.",
      "icon_url": "https://fivetran.com/integrations/helpscout/resources/helpscout.svg",
      "provider": "fivetran"
    },
    {
      "id": "heroku_kafka",
      "name": "Heroku Kafka",
      "type": "Events",
      "description": "Heroku Kafka is a managed distributed streaming platform",
      "icon_url": "https://fivetran.com/integrations/kafka/resources/heroku_kafka.svg",
      "provider": "fivetran"
    },
    {
      "id": "heroku_postgres",
      "name": "Heroku Postgres",
      "type": "Database",
      "description": "Heroku Postgres is a hosted version of the popular Postgres database on the Heroku platform",
      "icon_url": "https://fivetran.com/integrations/postgres/resources/heroku.png",
      "provider": "fivetran"
    },
    {
      "id": "hubspot",
      "name": "Hubspot",
      "type": "Marketing",
      "description": "Hubspot is a marketing and sales platform",
      "icon_url": "https://fivetran.com/integrations/hubspot/resources/hubspot.svg",
      "provider": "fivetran"
    },
    {
      "id": "instagram_business",
      "name": "Instagram Business",
      "type": "Marketing",
      "description": "Instagram Business provides Insights and Metadata of Instagram Business Accounts Linked with Facebook Pages",
      "icon_url": "https://fivetran.com/integrations/instagram/resources/instagram.png",
      "provider": "fivetran"
    },
    {
      "id": "intercom",
      "name": "Intercom",
      "type": "Support",
      "description": "Intercom provides customer messaging apps for sales, marketing, and support.",
      "icon_url": "https://fivetran.com/integrations/intercom/resources/intercom.svg",
      "provider": "fivetran"
    },
    {
      "id": "iterable",
      "name": "Iterable",
      "type": "Marketing",
      "description": "Email, Push, SMS & InApp Analytics for Iterable",
      "icon_url": "https://fivetran.com/integrations/iterable/resources/iterable.svg",
      "provider": "fivetran"
    },
    {
      "id": "jira",
      "name": "Jira",
      "type": "Engineering",
      "description": "Issue tracker",
      "icon_url": "https://fivetran.com/integrations/jira/resources/jira.svg",
      "provider": "fivetran"
    },
    {
      "id": "kinesis",
      "name": "Kinesis",
      "type": "File",
      "description": "Load files stored in S3",
      "icon_url": "https://fivetran.com/integrations/kinesis/resources/kinesis.svg",
      "provider": "fivetran"
    },
    {
      "id": "klaviyo",
      "name": "Klaviyo",
      "type": "Marketing",
      "description": "Klaviyo is an online platform to send email, Facebook and Instagram marketing campaigns",
      "icon_url": "https://fivetran.com/integrations/klaviyo/resources/klaviyo.svg",
      "provider": "fivetran"
    },
    {
      "id": "kustomer",
      "name": "Kustomer",
      "type": "Support",
      "description": "A software platform for customer experience, service, and support, integrated with a number of sources to give support staff a complete picture of a customer when they contact the company.",
      "icon_url": "https://fivetran.com/integrations/kustomer/resources/kustomer.svg",
      "provider": "fivetran"
    },
    {
      "id": "lever",
      "name": "Lever",
      "type": "HumanResources",
      "description": "Lever is a recruiting software that helps companies tackle problems in recruitment process",
      "icon_url": "https://fivetran.com/integrations/lever/resources/lever.svg",
      "provider": "fivetran"
    },
    {
      "id": "light_speed_retail",
      "name": "Lightspeed Retail",
      "type": "Finance",
      "description": "Lightspeed Retail is a point of sale solution for retail businesses",
      "icon_url": "https://fivetran.com/integrations/lightspeed_retail/resources/lightspeed.svg",
      "provider": "fivetran"
    },
    {
      "id": "linkedin_ads",
      "name": "LinkedIn Ad Analytics",
      "type": "Marketing",
      "description": "LinkedIn Ad Analytics is an API that provides data and metrics on LinkedIn Ads.",
      "icon_url": "https://fivetran.com/integrations/linkedin/resources/linkedin_ads.svg",
      "provider": "fivetran"
    },
    {
      "id": "linkedin_company_pages",
      "name": "LinkedIn Company Pages",
      "type": "Marketing",
      "description": "Company Pages Statistics, Reactions and Social Metadata.",
      "icon_url": "https://fivetran.com/integrations/linkedin/resources/linkedin_company_pages.svg",
      "provider": "fivetran"
    },
    {
      "id": "magento_mysql",
      "name": "Magento MySQL",
      "type": "Database",
      "description": "Magento MySQL integrates with Magento by connecting directly to its MySQL backing database",
      "icon_url": "https://fivetran.com/integrations/mysql/resources/logos/magento.png",
      "provider": "fivetran"
    },
    {
      "id": "magento_mysql_rds",
      "name": "Magento MySQL RDS",
      "type": "Database",
      "description": "Magento MySQL RDS integrates with Magento by connecting directly to its MySQL backing database on Amazon RDS",
      "icon_url": "https://fivetran.com/integrations/mysql/resources/logos/magento.png",
      "provider": "fivetran"
    },
    {
      "id": "mailchimp",
      "name": "Mailchimp",
      "type": "Marketing",
      "description": "Mailchimp is a world leading email newsletter service",
      "icon_url": "https://fivetran.com/integrations/mailchimp/resources/mailchimp.svg",
      "provider": "fivetran"
    },
    {
      "id": "mandrill",
      "name": "Mandrill",
      "type": "Marketing",
      "description": "Mandrill is an email delivery API",
      "icon_url": "https://fivetran.com/integrations/mandrill/resources/mandrill.svg",
      "provider": "fivetran"
    },
    {
      "id": "maria",
      "name": "MariaDB",
      "type": "Database",
      "description": "MariaDB is a fork of the popular MySQL database",
      "icon_url": "https://fivetran.com/integrations/mysql/resources/logos/maria.png",
      "provider": "fivetran"
    },
    {
      "id": "maria_rds",
      "name": "MariaDB RDS",
      "type": "Database",
      "description": "MariaDB RDS is a fork of the popular MySQL database on Amazon RDS",
      "icon_url": "https://fivetran.com/integrations/mysql/resources/logos/maria.png",
      "provider": "fivetran"
    },
    {
      "id": "marin",
      "name": "Marin Software",
      "type": "Marketing",
      "description": "Marin Software is an online advertising platform to measure, manage, and optimize ad spending across the web and mobile devices.",
      "icon_url": "https://fivetran.com/integrations/marin/resources/logo.png",
      "provider": "fivetran"
    },
    {
      "id": "marketo",
      "name": "Marketo",
      "type": "Marketing",
      "description": "Marketo is a personalizable marketing automation tool",
      "icon_url": "https://fivetran.com/integrations/marketo/resources/marketo.svg",
      "provider": "fivetran"
    },
    {
      "id": "mavenlink",
      "name": "Mavenlink",
      "type": "Productivity",
      "description": "Mavenlink unites project management, time tracking, resource planning and more into one place.",
      "icon_url": "https://fivetran.com/integrations/mavenlink/resources/mavenlink.svg",
      "provider": "fivetran"
    },
    {
      "id": "bingads",
      "name": "Microsoft Advertising",
      "type": "Marketing",
      "description": "Microsoft Advertising displays an advertisement on Bing search results",
      "icon_url": "https://fivetran.com/integrations/bingads/resources/bingads.svg",
      "provider": "fivetran"
    },
    {
      "id": "mixpanel",
      "name": "Mixpanel",
      "type": "Events",
      "description": "Mixpanel allows you to track people and events in your mobile apps",
      "icon_url": "https://fivetran.com/integrations/mixpanel/resources/mixpanel.svg",
      "provider": "fivetran"
    },
    {
      "id": "mongo",
      "name": "MongoDB",
      "type": "Database",
      "description": "MongoDB is a schema-less database",
      "icon_url": "https://fivetran.com/integrations/mongo/resources/mongo.svg",
      "provider": "fivetran"
    },
    {
      "id": "mongo_sharded",
      "name": "MongoDB Sharded",
      "type": "Database",
      "description": "MongoDB is a schema-less database",
      "icon_url": "https://fivetran.com/integrations/mongo/resources/mongo.svg",
      "provider": "fivetran"
    },
    {
      "id": "mysql",
      "name": "MySQL",
      "type": "Database",
      "description": "MySQL is a database system widely used to keep in-house custom data",
      "icon_url": "https://fivetran.com/integrations/mysql/resources/logos/mysql.png",
      "provider": "fivetran"
    },
    {
      "id": "mysql_rds",
      "name": "MySQL RDS",
      "type": "Database",
      "description": "MySQL RDS is a hosted version of the popular MySQL database on the Amazon RDS platform",
      "icon_url": "https://fivetran.com/integrations/mysql/resources/logos/mysql.png",
      "provider": "fivetran"
    },
    {
      "id": "netsuite_suiteanalytics",
      "name": "NetSuite SuiteAnalytics",
      "type": "Finance",
      "description": "NetSuite SuiteAnalytics provides embedded real-time insights for your custom applications",
      "icon_url": "https://fivetran.com/integrations/netsuite/resources/netsuite.svg",
      "provider": "fivetran"
    },
    {
      "id": "optimizely",
      "name": "Optimizely",
      "type": "Events",
      "description": "Optimizely delivers customer experience optimization software.",
      "icon_url": "https://fivetran.com/integrations/optimizely/resources/optimizely.svg",
      "provider": "fivetran"
    },
    {
      "id": "oracle",
      "name": "Oracle",
      "type": "Database",
      "description": "Oracle Database is a database system widely used to keep in-house custom data",
      "icon_url": "https://fivetran.com/integrations/oracle/resources/oracle.svg",
      "provider": "fivetran"
    },
    {
      "id": "oracle_ebs",
      "name": "Oracle EBS",
      "type": "Database",
      "description": "Oracle E-Business Suite is an enterprise resource planning system developed by Oracle",
      "icon_url": "https://fivetran.com/integrations/oracle/resources/oracle.svg",
      "provider": "fivetran"
    },
    {
      "id": "oracle_rac",
      "name": "Oracle RAC",
      "type": "Database",
      "description": "Oracle Real Application Cluster is a database system widely used to keep in-house custom data",
      "icon_url": "https://fivetran.com/integrations/oracle/resources/oracle.svg",
      "provider": "fivetran"
    },
    {
      "id": "oracle_rds",
      "name": "Oracle RDS",
      "type": "Database",
      "description": "Oracle Database RDS is a hosted version of the popular Oracle database on the Amazon RDS platform",
      "icon_url": "https://fivetran.com/integrations/oracle/resources/oracle.svg",
      "provider": "fivetran"
    },
    {
      "id": "outbrain",
      "name": "Outbrain",
      "type": "Marketing",
      "description": "Outbrain is a platform that draws data-driven connections between interests and actions.",
      "icon_url": "https://fivetran.com/integrations/outbrain/resources/outbrain_logo.png",
      "provider": "fivetran"
    },
    {
      "id": "outreach",
      "name": "Outreach",
      "type": "Sales",
      "description": "Outreach is a sales engagement platform and automation service",
      "icon_url": "https://fivetran.com/integrations/outreach/resources/outreach.svg",
      "provider": "fivetran"
    },
    {
      "id": "pardot",
      "name": "Pardot",
      "type": "Marketing",
      "description": "Pardot is a CRM tool",
      "icon_url": "https://fivetran.com/integrations/pardot/resources/pardot.svg",
      "provider": "fivetran"
    },
    {
      "id": "pendo",
      "name": "Pendo",
      "type": "Events",
      "description": "Pendo helps product teams understand and guide users to create product experiences that customers love.",
      "icon_url": "https://fivetran.com/integrations/pendo/resources/pendo.svg",
      "provider": "fivetran"
    },
    {
      "id": "pinterest_ads",
      "name": "Pinterest Ads",
      "type": "Marketing",
      "description": "Pinterest is an online pin board for collecting and sharing multimedia",
      "icon_url": "https://fivetran.com/integrations/pinterest/resources/pinterest-ads.svg",
      "provider": "fivetran"
    },
    {
      "id": "pipedrive",
      "name": "Pipedrive",
      "type": "Sales",
      "description": "CRM platform made for salespeople",
      "icon_url": "https://fivetran.com/integrations/pipedrive/resources/pipedrive.svg",
      "provider": "fivetran"
    },
    {
      "id": "prestashop",
      "name": "PrestaShop",
      "type": "Sales",
      "description": "Ecommerce platform",
      "icon_url": "",
      "provider": "fivetran"
    },
    {
      "id": "postgres",
      "name": "Postgres",
      "type": "Database",
      "description": "Postgres is a database system widely used to keep in-house custom data",
      "icon_url": "https://fivetran.com/integrations/postgres/resources/postgres.svg",
      "provider": "fivetran"
    },
    {
      "id": "postgres_rds",
      "name": "Postgres RDS",
      "type": "Database",
      "description": "Postgres RDS is a hosted version of the popular Postgres database on the Amazon RDS platform",
      "icon_url": "https://fivetran.com/integrations/postgres/resources/postgres.svg",
      "provider": "fivetran"
    },
    {
      "id": "quickbooks",
      "name": "QuickBooks",
      "type": "Finance",
      "description": "QuickBooks integration",
      "icon_url": "https://fivetran.com/integrations/quickbooks/resources/quickbooks.svg",
      "provider": "fivetran"
    },
    {
      "id": "recharge",
      "name": "ReCharge",
      "type": "Finance",
      "description": "ReCharge provides a recurring billing and subscriptions solution for ecommerce",
      "icon_url": "https://fivetran.com/integrations/recharge/resources/recharge.svg",
      "provider": "fivetran"
    },
    {
      "id": "recurly",
      "name": "Recurly",
      "type": "Finance",
      "description": "Recurly offers subscription management services.",
      "icon_url": "https://fivetran.com/integrations/recurly/resources/recurly.svg",
      "provider": "fivetran"
    },
    {
      "id": "s3",
      "name": "S3",
      "type": "File",
      "description": "Load files stored in S3",
      "icon_url": "https://fivetran.com/integrations/s3/resources/s3.svg",
      "provider": "fivetran"
    },
    {
      "id": "concur",
      "name": "SAP Concur",
      "type": "Finance",
      "description": "SAP Concur is the leading accounting software for companies of any size",
      "icon_url": "https://fivetran.com/integrations/sap_concur/resources/sap_concur_logo.svg",
      "provider": "fivetran"
    },
    {
      "id": "sap_hana",
      "name": "SAP HANA",
      "type": "Database",
      "description": "SAP HANA is an in-memory, column-oriented, relational database management system",
      "icon_url": "https://fivetran.com/integrations/sap_s4hana/resources/sap-hana.jpeg",
      "provider": "fivetran"
    },
    {
      "id": "sap_s4hana",
      "name": "SAP S/4HANA",
      "type": "Finance",
      "description": "Enterprise Resource Planning system running on the HANA database",
      "icon_url": "https://fivetran.com/integrations/sap_s4hana/resources/sap-s4hana.svg",
      "provider": "fivetran"
    },
    {
      "id": "sftp",
      "name": "SFTP",
      "type": "File",
      "description": "Load files stored in an SFTP server",
      "icon_url": "https://fivetran.com/integrations/sftp/resources/sftp.svg",
      "provider": "fivetran"
    },
    {
      "id": "sql_server",
      "name": "SQL Server",
      "type": "Database",
      "description": "Microsoft SQL Server is a database system widely used to keep in-house custom data",
      "icon_url": "https://fivetran.com/integrations/sql_server/resources/sql_server.svg",
      "provider": "fivetran"
    },
    {
      "id": "sql_server_rds",
      "name": "SQL Server RDS",
      "type": "Database",
      "description": "Microsoft SQL Server RDS is a hosted version of the popular Microsoft SQL Server database on the Amazon RDS platform",
      "icon_url": "https://fivetran.com/integrations/sql_server/resources/sql_server.svg",
      "provider": "fivetran"
    },
    {
      "id": "sage_intacct",
      "name": "Sage Intacct",
      "type": "Finance",
      "description": "Sage Intacct is the leading accounting software for companies of any size",
      "icon_url": "https://fivetran.com/integrations/sage_intacct/resources/sage_intacct_logo.svg",
      "provider": "fivetran"
    },
    {
      "id": "sailthru",
      "name": "Sailthru",
      "type": "Marketing",
      "description": "Sailthru provides interface for scheduling and sending campaign and transaction emails.",
      "icon_url": "https://fivetran.com/integrations/sailthru/resources/sailthru.svg",
      "provider": "fivetran"
    },
    {
      "id": "salesforce",
      "name": "Salesforce",
      "type": "Sales",
      "description": "Salesforce is a customer relationship manager (CRM) application",
      "icon_url": "https://fivetran.com/integrations/salesforce/resources/salesforce.svg",
      "provider": "fivetran"
    },
    {
      "id": "salesforce_marketing_cloud",
      "name": "Salesforce Marketing Cloud",
      "type": "Marketing",
      "description": "Salesforce Marketing Cloud provides digital marketing automation, analytics, and lead management for B2B and B2C companies.",
      "icon_url": "https://fivetran.com/integrations/salesforce_marketing_cloud/resources/salesforce_marketing_cloud.svg",
      "provider": "fivetran"
    },
    {
      "id": "salesforce_sandbox",
      "name": "Salesforce sandbox",
      "type": "Sales",
      "description": "Salesforce is a customer relationship manager (CRM) application",
      "icon_url": "https://fivetran.com/integrations/salesforce/resources/salesforce_sandbox.png",
      "provider": "fivetran"
    },
    {
      "id": "segment",
      "name": "Segment",
      "type": "Events",
      "description": "Segment tracks events on your web site or app",
      "icon_url": "https://fivetran.com/integrations/segment/resources/segment.svg",
      "provider": "fivetran"
    },
    {
      "id": "sendgrid",
      "name": "SendGrid",
      "type": "Marketing",
      "description": "SendGrid is a customer communication platform for transactional and marketing emails",
      "icon_url": "https://fivetran.com/integrations/sendgrid/resources/sendgrid.svg",
      "provider": "fivetran"
    },
    {
      "id": "servicenow",
      "name": "ServiceNow",
      "type": "Productivity",
      "description": "ServiceNow provides IT Management and other services",
      "icon_url": "https://fivetran.com/integrations/service_now/resources/logo.svg",
      "provider": "fivetran"
    },
    {
      "id": "shopify",
      "name": "Shopify",
      "type": "Sales",
      "description": "Complete e-commerce solution that allows you to set up an online store to sell your goods",
      "icon_url": "https://fivetran.com/integrations/shopify/resources/shopify.svg",
      "provider": "fivetran"
    },
    {
      "id": "snapchat_ads",
      "name": "Snapchat Ads",
      "type": "Marketing",
      "description": "Snapchat is a multimedia messaging app",
      "icon_url": "https://fivetran.com/integrations/snapchat_ads/resources/snapchat.png",
      "provider": "fivetran"
    },
    {
      "id": "snowplow",
      "name": "Snowplow",
      "type": "Events",
      "description": "Snowplow is an open-source JavaScript, iOS and Android event tracker",
      "icon_url": "https://fivetran.com/integrations/snowplow/resources/snowplow.svg",
      "provider": "fivetran"
    },
    {
      "id": "splunk",
      "name": "Splunk",
      "type": "Engineering",
      "description": "Software platform to search, analyze and visualize the machine-generated data gathered from the websites, applications, sensors, devices etc",
      "icon_url": "https://fivetran.com/integrations/splunk/resources/splunk.svg",
      "provider": "fivetran"
    },
    {
      "id": "square",
      "name": "Square",
      "type": "Finance",
      "description": "Square, Inc. is a financial services, merchant services aggregator, and mobile payment company.",
      "icon_url": "https://fivetran.com/integrations/square/resources/square.png",
      "provider": "fivetran"
    },
    {
      "id": "stripe",
      "name": "Stripe",
      "type": "Finance",
      "description": "Stripe is a credit card payment system that helps you track recurring charges",
      "icon_url": "https://fivetran.com/integrations/stripe/resources/stripe.svg",
      "provider": "fivetran"
    },
    {
      "id": "stripe_test",
      "name": "Stripe test mode",
      "type": "Finance",
      "description": "Stripe is a credit card payment system that helps you track recurring charges",
      "icon_url": "https://fivetran.com/integrations/stripe/resources/stripe.svg",
      "provider": "fivetran"
    },
    {
      "id": "survey_monkey",
      "name": "SurveyMonkey",
      "type": "Support",
      "description": "SurveyMonkey provides online survey tool to capture the voices and opinions of the people.",
      "icon_url": "https://fivetran.com/integrations/survey_monkey/resources/survey_monkey.svg",
      "provider": "fivetran"
    },
    {
      "id": "taboola",
      "name": "Taboola",
      "type": "Marketing",
      "description": "Taboola develops and markets a service for online content publishers and advertisers that recommends digital content to website users",
      "icon_url": "https://fivetran.com/integrations/taboola/resources/taboola.svg",
      "provider": "fivetran"
    },
    {
      "id": "tiktok_ads",
      "name": "Tiktok Ads",
      "type": "Marketing",
      "description": "Tiktok Ads provides attribute data on Tiktok Ads Accounts and Reports",
      "icon_url": "https://fivetran.com/integrations/tiktok_ads/resources/tiktok-logo.svg",
      "provider": "fivetran"
    },
    {
      "id": "twilio",
      "name": "Twilio",
      "type": "Engineering",
      "description": "Twilio is a CPaaS that allows to programmatically make and receive phone calls, send and receive text messages.",
      "icon_url": "https://fivetran.com/integrations/twilio/resources/twilio.svg",
      "provider": "fivetran"
    },
    {
      "id": "twitter",
      "name": "Twitter",
      "type": "Marketing",
      "description": "Twitter provides reporting data for Organic Tweets",
      "icon_url": "https://fivetran.com/integrations/twitter/resources/twitter_ads.svg",
      "provider": "fivetran"
    },
    {
      "id": "twitter_ads",
      "name": "Twitter Ads",
      "type": "Marketing",
      "description": "Twitter Ads provides attribute data on Twitter Ads Accounts and Reports",
      "icon_url": "https://fivetran.com/integrations/twitter/resources/twitter_ads.svg",
      "provider": "fivetran"
    },
    {
      "id": "uservoice",
      "name": "UserVoice",
      "type": "Support",
      "description": "UserVoice is a customer feedback processing system",
      "icon_url": "https://fivetran.com/integrations/uservoice/resources/uservoice.svg",
      "provider": "fivetran"
    },
    {
      "id": "yahoo_gemini",
      "name": "Verizon Media",
      "type": "Marketing",
      "description": "Verizon Media is Verizon’s platform for native advertising and mobile search ads",
      "icon_url": "https://fivetran.com/integrations/yahoo_gemini/resources/verizon.png",
      "provider": "fivetran"
    },
    {
      "id": "webhooks",
      "name": "Webhooks",
      "type": "Events",
      "description": "Fivetran can accept POST requests from any webhook and import it into your data warehouse",
      "icon_url": "https://fivetran.com/integrations/webhooks/resources/webhooks.svg",
      "provider": "fivetran"
    },
    {
      "id": "workday",
      "name": "Workday",
      "type": "HumanResources",
      "description": "Workday is a cloud ERP system for finance, HR, and planning",
      "icon_url": "https://fivetran.com/integrations/workday/resources/workday.png",
      "provider": "fivetran"
    },
    {
      "id": "xero",
      "name": "Xero",
      "type": "Finance",
      "description": "Online accounting",
      "icon_url": "https://fivetran.com/integrations/xero/resources/xero.svg",
      "provider": "fivetran"
    },
    {
      "id": "youtube_analytics",
      "name": "YouTube Analytics",
      "type": "Marketing",
      "description": "YouTube Analytics enables users to understand how their videos are performing on YouTube",
      "icon_url": "https://fivetran.com/integrations/youtube_analytics/resources/youtube_analytics.svg",
      "provider": "fivetran"
    },
    {
      "id": "zendesk_chat",
      "name": "Zendesk Chat",
      "type": "Support",
      "description": "Zendesk Chat is a support chat tool",
      "icon_url": "https://fivetran.com/integrations/zendesk_chat/resources/zendesk_chat.svg",
      "provider": "fivetran"
    },
    {
      "id": "zendesk_sunshine",
      "name": "Zendesk Sunshine",
      "type": "Support",
      "description": "Zendesk Sunshine is a open and flexible CRM platform.",
      "icon_url": "https://fivetran.com/integrations/zendesk_sunshine/resources/logo-zendesk.svg",
      "provider": "fivetran"
    },
    {
      "id": "zendesk",
      "name": "Zendesk Support",
      "type": "Support",
      "description": "Zendesk is a web-based help desk support tool",
      "icon_url": "https://fivetran.com/integrations/zendesk/resources/zendesk.svg",
      "provider": "fivetran"
    },
    {
      "id": "zuora",
      "name": "Zuora",
      "type": "Finance",
      "description": "Zuora allows businesses to quote, order, bill, recognize revenue, report, and automate the entire customer lifecycle from a single platform.",
      "icon_url": "https://fivetran.com/integrations/zuora/resources/zuora.svg",
      "provider": "fivetran"
    },
    {
      "id": "zuora_sandbox",
      "name": "Zuora Sandbox",
      "type": "Finance",
      "description": "Zuora allows businesses to quote, order, bill, recognize revenue, report, and automate the entire customer lifecycle from a single platform.",
      "icon_url": "https://fivetran.com/integrations/zuora/resources/zuora.svg",
      "provider": "fivetran"
    }
  ];

  const options = connectors.map(c => {
    return { value: c.id, label: c.name, icon: c.icon_url, description: c.description, provider: c.provider };
  });

  const handleChange = (e) => {
    if (e.target && e.target.id === "DatasourceGuide__name") {
      setTask(taskState => ({
        ...taskState,
        name: e.target.value
      }));
    } else {
      setTask(taskState => ({
        ...taskState,
        type: e.value,
        description: e.description,
        icon: e.icon,
        provider: e.provider
      }));
      switch (e.value) {
        case 'google_sheets':
        case 'google_analytics_4':
          setName(e.value);
          break;
        default:
          setName('default');
      }
    }
  };

  const Validation = (props) => {
    if (props.id === 'name') {
      if (!nameValidation.test(task.name)) {
        return "Name must only containg lower case letters (a-z) and/or _ or numbers";
      }
      if (task.type === 'google_sheets' && task.name === '') {
        return "Table name is required for Google Sheets data sources";
      }
    }
    return "";
  };

  if (!isLoaded) return <LoadingDots />;
  if (error) return <div>{error.message}</div>;

  return (
    <div className="DatasourceGuide">
      <PageHeader pretitle="Data sources" title="Add a new data source" />

      <Form className="DatasourceGuide__form" onSubmit={handleSubmit}>

        <FieldRow>
          <Field
            type="select"
            id="DatasourceGuide__type"
            className="DatasourceGuide__type"
            name="Type"
            options={options}
            onChange={handleChange}
            classNamePrefix="Select"
            placeholder="Select source type..."
          />
        </FieldRow>

        {task.description &&
          (
            <div className="DatasourceGuide__typedescription">
              <p>{task.description}</p>
              <img src={task.icon} align="right" width="100" alt={task.type} />
            </div>
          )
        }

        {task.type === 'google_analytics_4' ?
          false :
          <FieldRow>
            <Field
              type="text"
              id="DatasourceGuide__name"
              className="DatasourceGuide__name"
              name="Name"
              value={task.name ? task.name : ''}
              onChange={handleChange}
              validation={<Validation id="name" />}
              placeholder={task.type === 'google_sheets' ? "Table name" : "Optional data source name"}
            />
          </FieldRow>
        }
        {
          {
            'google_analytics_4': <p>Dataset name will be: analytics_[ your google analytics 4 property id ]<br /></p>,
            'google_sheets': <p>Dataset name will be: google_sheets and table name will be {task.name}<br /></p>,
            'default': <p>Dataset name will be: {task.type}{task.name !== null && task.name !== '' && task.name !== undefined ? '_' + task.name : ''}<br /></p>
          }[name]
        }

        <SubmitButton isLoaded={isLoaded} error={error} />
      </Form>
    </div>
  )
}

export default DatasourceGuide;
