import './style.scss';
import React, { useState, useEffect } from 'react';
import CognyAPI from 'components/_classes/CognyAPI';
import AccessListing from 'components/atoms/AccessListing';
import LoadingDots from 'components/atoms/LoadingDots';
import Button from 'components/atoms/Button';
import { useCookies } from 'react-cookie';
import { useParams } from 'react-router-dom';
import { useAppData } from 'hooks/useAppData';

function SettingsUsers(props) {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [access, setAccess] = useState([]);
  const [cookies] = useCookies(["token", "project"]);
  const params = useParams();
  const warehouseId = params.warehouse_id;
  const { getPath } = useAppData();

  useEffect(() => {
    let mounted = true;
    if (cookies.token) {
      const api = new CognyAPI(cookies.token);
      api.getWarehouseAccess(warehouseId)
        .then(
          (result) => {
            if (!mounted) return;
            setAccess(result.access);
            setIsLoaded(true);
          },
          (error) => {
            if (!mounted) return;
            setError(error);
            setIsLoaded(true);
          }
        );
    }
    return () => {
      mounted = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookies])

  const handleDeleteUser = (email) => {
    if (window.confirm('Are you sure you want to delete this user?')) {
      const api = new CognyAPI(cookies.token);
      api.removeWarehouseAccess(warehouseId, email)
        .then(() => {
          setAccess(prevAccess => prevAccess.filter(user => user.email !== email));
        })
        .catch(error => {
          console.error('Error deleting user:', error);
          setError(error);
        });
    }
  };
// icon="trash" 
                          
  if (error) {
    console.log(error);
    return <div>Error: {error.message}</div>;
  } else {
    return (
      <div className="SettingsUsers">
        <div className="SettingsUsers__welcome">
          <h1 className="SettingsUsers__title">Users</h1>
          <Button type="link" href={getPath("access", "new")} className="SettingsUsers__addTask" icon="plus">Add new user</Button>
        </div>
        {
          !isLoaded ?
            <LoadingDots />
            :
            <div className="SettingsUsers__box SettingsUsersBox--recurring">
              {
                access.length ?
                  access.map(ds => (
                    <AccessListing 
                      key={ds.gid} 
                      task={ds} 
                      warehouseId={warehouseId}
                      onDelete={() => handleDeleteUser(ds.email)}
                    />
                  ))
                  :
                  <p>No users?!</p>
              }
            </div>
        }
      </div>
    );

  }
}

export default SettingsUsers;
