import './style.scss';

import React from 'react';
import Button from 'components/atoms/Button';

function AccessListing({ task, warehouseId, onDelete }) {
  
  return (
    <div className="AccessListing" key={task.id}>
      <div className="AccessListing__main">
        <div className="AccessListing__header">
          <h3 className="AccessListing__title">
            {task.email}
          </h3>
          <Button 
            type="button" 
            className="AccessListing__deleteUser" 
            onClick={onDelete}
          >
            Delete
          </Button>
        </div>
      </div>
      <div className="AccessListing__meta">
        <h4 className="AccessListing__title">Role: {task.role}</h4>
      </div>
    </div>
  );
}

export default AccessListing;
