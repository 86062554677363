import './style.scss';
import React from "react";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import remarkToc from "remark-toc";
import rehypeSlug from "rehype-slug";
import rehypeKatex from "rehype-katex";
import 'katex/dist/katex.min.css';
import rehypeSanitize from "rehype-sanitize";
import { visit } from 'unist-util-visit';
import Code from "components/molecules/Code";

const ImageBlock = (props) => {
  const { src, alt } = props;
  return <img src={src} alt={alt} className="MarkdownBlock__image" />;
};

const TableBlock = ({ node, children }) => {
  return (
    <div className="MarkdownBlock__table-container">
      <table className="MarkdownBlock__table">
        {children}
      </table>
    </div>
  );
};

const CodeBlock = (props) => {
  const { className = "", children } = props;
  const match = /language-(\w+)/.exec(className || '');
  const language = match ? match[1] : null;

  if (language) {
    return (
      <Code
        language={language}
        className={"MarkdownBlock__code"}
      >
        {children}
      </Code>
    );
  }

  return (
    <code className={"MarkdownBlock__inline-code"}>
      {children}
    </code>
  );

};

const PreBlock = (props) => {
  const { children } = props;

  if (children && typeof children !== "string") {
    return <>{children}</>;
  } else {
    return <pre className="MarkdownBlock__pre">{children}</pre>;
  }
};

const StrongBlock = (props) => {
  const { children } = props;

  return <strong className="MarkdownBlock__strong">{children}</strong>;
};

const remarkCleanup = () => {
  return (tree) => {
    visit(tree, 'heading', (node) => {
      // Convert headings that contain only dashes (like #### ---) to a horizontal rule
      if (
        node.children &&
        node.children.length === 1 &&
        node.children[0].type === 'text' &&
        node.children[0].value.trim() === '---'
      ) {
        node.type = 'thematicBreak';
        node.children = [];
      } else if (node.children && Array.isArray(node.children)) {
        // Remove {#id} markup from titles
        node.children.forEach((child) => {
          if (child.type === 'text' && typeof child.value === 'string') {
            child.value = child.value.replace(/\s*{#([^}]+)}/g, '');
          }
        });
      }
    });

    visit(tree, 'thematicBreak', (node, index, parent) => {
      parent.children[index] = {
        type: 'html',
        value: '<hr />',
      };
    });

    visit(tree, 'text', (node) => {
      if (node.value.trim() === '{#heading}') {
        node.value = '';
      }
    });
  };
};

// Custom rehype plugin to add "user-content-" prefix to TOC links
const rehypeAddUserContentPrefix = () => {
  return (tree) => {
    visit(tree, 'element', (node) => {
      if (node.tagName === 'a' && node.properties && node.properties.href) {
        node.properties.href = node.properties.href.replace(/^#/, '#user-content-');
      }
    });
  };
};

// Custom rehype plugin to add a class to the TOC <ul> element and remove empty TOC items
const rehypeAddTocClass = () => {
  return (tree) => {
    let tocFound = false;

    visit(tree, 'element', (node, index, parent) => {
      // Check for the start of a TOC by identifying a heading indicating a TOC presence
      if (node.tagName === 'h2' && node.children[0] && node.children[0].value === 'Table of Contents') {
        tocFound = true;
      }

      // Add the class to the TOC <ul> element once it follows the TOC heading
      if (tocFound && node.tagName === 'ul') {
        node.properties = node.properties || {};
        node.properties.className = (node.properties.className || []).concat('MarkdownBlock__toc');
        tocFound = false; // Reset to ensure we only target the immediate TOC
      }

      // Remove empty TOC items
      if (node.tagName === 'li' && parent && parent.tagName === 'ul') {
        const link = node.children && node.children[0];
        if (link && link.tagName === 'a' && (!link.children || link.children.length === 0)) {
          parent.children.splice(index, 1); // Remove the empty TOC item
        }
      }
    });
  };
};

function MarkdownBlock(props) {
  const { children, enableIndex } = props;

  const remarkPlugins = [
    remarkGfm,
    ...(enableIndex ? [remarkCleanup, [remarkToc, { heading: 'Table of Contents' }]] : []),
  ];

  const rehypePlugins = [
    rehypeSlug,
    rehypeKatex,
    [rehypeSanitize, {}],
    ...(enableIndex ? [rehypeAddUserContentPrefix, rehypeAddTocClass] : []),
  ];

  return (
    <div
      className={
        "MarkdownBlock"
        + (enableIndex ? " MarkdownBlock--with-index" : "")
      }
    >
      <Markdown
        remarkPlugins={remarkPlugins}
        rehypePlugins={rehypePlugins}
        components={{
          table: TableBlock,
          code: CodeBlock,
          pre: PreBlock,
          strong: StrongBlock,
          img: ImageBlock,
        }}
      >
        {children}
      </Markdown>
    </div>
  );
}

export default MarkdownBlock;