import './style.scss';
import React, { useState, useEffect } from 'react';
import OutsideBox from 'outside/components/OutsideBox';
import LoadingDots from 'components/atoms/LoadingDots';
import MarkdownBlock from 'components/molecules/MarkdownBlock';
import SubpageHero from 'outside/components/SubpageHero';

function Documentation() {
  const [content, setContent] = useState('');
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch('/docs.md')
      .then(response => response.text())
      .then(text => {
        setContent(text);
        setIsLoaded(true);
      })
      .catch(error => {
        setError(error);
        console.error('Error loading documentation:', error);
      });
  }, []);

  useEffect(() => {
    if (isLoaded) {
      // Scroll to the anchor when the page initially loads
      const anchor = window.location.hash.substring(1); // Remove the '#' from the hash
      const headerElement = document.querySelector('.OutsideHeader');
      const headerOffset = headerElement ? headerElement.offsetHeight + 20 : 0;

      if (anchor) {
        const element = document.getElementById(anchor);
        if (element) {
          const elementPosition = element.getBoundingClientRect().top + window.scrollY;
          window.scrollTo({ top: elementPosition - headerOffset, behavior: 'smooth' });
        }
      }

      // Handle anchor link clicks manually to prevent page reload
      const handleAnchorClick = (event) => {
        if (event.target.tagName === 'A' && event.target.hash) {
          event.preventDefault(); // Prevent the default anchor behavior
          const targetId = event.target.hash.substring(1);
          const targetElement = document.getElementById(targetId);
          if (targetElement) {
            const elementPosition = targetElement.getBoundingClientRect().top + window.scrollY;
            window.scrollTo({ top: elementPosition - headerOffset, behavior: 'smooth' });
            window.history.pushState(null, '', `#${targetId}`); // Update the URL without reloading the page
          }
        }
      };

      document.addEventListener('click', handleAnchorClick);

      // Cleanup event listener on component unmount
      return () => {
        document.removeEventListener('click', handleAnchorClick);
      };
    }
  }, [isLoaded]);

  if (error) {
    return (
      <OutsideBox classBase="Documentation">
        <div className="Documentation">
          <p>Error loading documentation</p>
        </div>
      </OutsideBox>
    );
  }

  if (!isLoaded) {
    return (
      <OutsideBox classBase="Documentation">
        <div className="Documentation">
          <LoadingDots />
        </div>
      </OutsideBox>
    );
  }

  return (
    <>
      <SubpageHero
        className="Documentation__hero"
        title="Documentation"
        tagline="Here you'll find everything you need to know about Cogny"
        size="wide"
      />
      <OutsideBox classBase="Documentation">
        <div className="Documentation">
          <MarkdownBlock enableIndex>{content}</MarkdownBlock>
        </div>
      </OutsideBox>
    </>
  );
}

export default Documentation;